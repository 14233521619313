import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  double as rule_double,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
} from 'vee-validate/dist/rules'
import de from 'vee-validate/dist/locale/de.json'

// eslint-disable-next-line object-curly-newline
import {
  validatorUrlValidator,
  validatorPassword,
  validatorNumber,
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const double = extend('double', rule_double)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const password = extend('password', {
  validate: validatorPassword,
  message:
    'Ihr {_field_} muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen und eine Ziffer enthalten',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL ist ungültig',
})

export const number = extend('number', {
  validate: validatorNumber,
  message: '{_field_} muss eine Zahl sein.',
})

export const percentage = extend('percentage', {
  message(count){
    return count === "100" ? null : "Die Summe der Anteile muss insgesamt 100% betragen."
  },
  validate(ts, count){
    return count[0] == 100;
  }
});

export const weight = extend('weight', {
  message(count, weight){
    return weight[0] === weight[1] ? null : `Die Summe der Anteile (wenn angegeben) muss ${weight[1]}kg betragen.`
  },
  validate(ts, count, weight){
    return count[0] == weight;
  }
});

localize('de', de)
